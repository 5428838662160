import React, { FC, useCallback, useEffect, useState } from 'react';
import { Loader, Typography } from '@maxi-innovation/ui-kit-frontend';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';

import { getNextQuestionRest, getPrevQuestionRest } from 'shared/api/survey';
import { NotificationHelper } from 'action/notificationHelper';
import { IDefaultValue, IQuestion, ISurvey } from './types';
import { convertNormalDate } from 'core/utils';
import { URL } from 'constant';
import { TakeQuestion } from './questions';
import { LastStep } from './questions/LastStep';
import { AlreadyStep } from './questions/Already';

import './style.scss';

interface IData {
	state: string | undefined;
	response?: {
		finishText: string;
		finishButtonName: string;
		finishButtonLink: string;
	};
}

export const TakeSurvey: FC<{ specialId?: number }> = ({ specialId }) => {
	const { id } = useParams<any>();
	const dispatch = useDispatch();
	const [survey, setSurvey] = useState<ISurvey>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [question, setQuestion] = useState<IQuestion>();
	const [defaultValue, setDefaultValue] = useState<IDefaultValue>();
	const [data, setData] = useState<IData>({ state: undefined, response: undefined });

	const clearState = () => {
		setSurvey(undefined);
		setQuestion(undefined);
		setData({ state: undefined, response: undefined });
		setDefaultValue(undefined);
	};

	const goToPrevQuestion = useCallback(
		(questionId: number | undefined) => {
			clearState();

			(async () => {
				const { response, responseCode, errorMessage } = await getPrevQuestionRest(
					id || specialId,
					questionId
				);

				if (responseCode) {
					dispatch(NotificationHelper(errorMessage, responseCode));
				} else {
					setSurvey(response.survey);
					setQuestion(response.question);
					setDefaultValue(response.answer);
					setData({
						state: response.state,
						response: undefined,
					});
				}
			})();
		},
		[id, specialId, dispatch]
	);

	const goToNextQuestion = useCallback(
		(questionId: number) => {
			clearState();

			(async () => {
				const { response, responseCode, errorMessage } = await getNextQuestionRest(
					id || specialId,
					questionId
				);

				if (responseCode) {
					dispatch(NotificationHelper(errorMessage, responseCode));
				} else {
					setSurvey(response.survey);
					setQuestion(response.question);
					setDefaultValue(response.answer);
					setData({
						state: response.state,
						response: undefined,
					});
				}
			})();
		},
		[id, specialId, dispatch]
	);

	useEffect(() => {
		(async () => {
			if (!id && !specialId) {
				return;
			}

			setIsLoading(true);
			const { response, responseCode, errorMessage } = await getNextQuestionRest(
				id || specialId
			);

			if (responseCode) {
				dispatch(NotificationHelper(errorMessage, responseCode));
			} else {
				setSurvey(response.survey);
				setQuestion(response.question);
				setDefaultValue(response.answer);
				setData({
					state: response.state,
					response: {
						finishText: response.survey.finishText,
						finishButtonName: response.survey.finishButtonName,
						finishButtonLink: response.survey.finishButtonLink,
					},
				});
			}
			setIsLoading(false);
		})();
	}, [dispatch, id, specialId]);

	return (
		<>
			{isLoading ? (
				<div className="survey-list-loader">
					<Loader variant="black" size="M" />
				</div>
			) : (
				<>
					{!!survey && (
						<div className="take-survey-info">
							<img
								src={URL + survey.cover.filePath}
								alt=""
								className="take-survey-info__preview"
							/>
							<div className="take-survey-info__wrap">
								<div className="take-survey-info__header">
									<Typography as="p" className="take-survey-info__date">
										Завершение опроса{' '}
										<b>{!!survey && convertNormalDate(survey.endDate)}</b>
									</Typography>
									{survey.anonymous && (
										<Typography
											as="p"
											color="sc77"
											className="take-survey-info__type"
										>
											Анонимный опрос
										</Typography>
									)}
								</div>
								<Typography
									as="p"
									variant="h2"
									weight="bold"
									className="take-survey-info__title"
								>
									{survey.title}
								</Typography>

								<Typography as="p" className="take-survey-info__text">
									{survey.description}
								</Typography>
							</div>
						</div>
					)}

					{!!question && survey && (
						<TakeQuestion
							surveyId={survey.id}
							firstQuestionId={survey.firstQuestionId}
							question={question}
							goToNextQuestion={goToNextQuestion}
							goToPrevQuestion={goToPrevQuestion}
							defaultValue={defaultValue}
						/>
					)}

					{survey && data.state === 'COMPLETED' && (
						<LastStep
							surveyId={survey?.id}
							goToPrevQuestion={goToPrevQuestion}
							setData={setData}
						/>
					)}

					{data.state === 'ALREADY_COMPLETED' && (
						<AlreadyStep
							specialId={specialId}
							finishText={data.response?.finishText}
							finishButtonName={data.response?.finishButtonName}
							finishButtonLink={data.response?.finishButtonLink}
						/>
					)}
				</>
			)}
		</>
	);
};
