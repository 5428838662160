import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { URL } from 'constant';
import { Link, useHistory } from 'react-router-dom';
import { Button, TypeButton, Icon, Loader, Typography } from '@maxi-innovation/ui-kit-frontend';

import { getSurveyForAdminRest, getSurveyListArchiveRest } from 'shared/api/survey';
import { NotificationHelper } from 'action/notificationHelper';
import { ISurveyList } from './types';
import { convertNormalDate } from 'core/utils';
import { useIsMobile } from 'hooks/useIsMobile';
import { copySurvey } from 'action/survey';

import './style.scss';

export const Archive = () => {
	const isMobile = useIsMobile();
	const dispatch = useDispatch();
	const history = useHistory();
	const [list, setList] = useState<ISurveyList[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			const { response, responseCode, errorMessage } = await getSurveyListArchiveRest();

			if (responseCode) {
				dispatch(NotificationHelper(errorMessage, responseCode));
			} else {
				setList(response);
			}
			setIsLoading(false);
		})();
	}, [dispatch]);

	const copyHandle = async (item: { id: number }) => {
		const { response, responseCode, errorMessage } = await getSurveyForAdminRest(item.id);

		if (responseCode) {
			dispatch(NotificationHelper(errorMessage, responseCode));
		} else {
			const data = {
				...response,
				questions: response.questions.map((question: any, index: number) => {
					return {
						type: question.answerType,
						title: question.text,
						...(question.answerType === 'SCALE'
							? {
									max: question.possibleAnswers.length,
									minLabel: question.minLabel,
									maxLabel: question.maxLabel,
							  }
							: {
									possibleAnswers: question.possibleAnswers.filter(
										(item: { ownAnswer: boolean }) => !item.ownAnswer && item
									),
									withOwnAnswer: !!question.possibleAnswers.find(
										(item: { ownAnswer: boolean }) => item.ownAnswer
									),
							  }),
						id: question.id,
						index: index,
						questionNumber: index + 1,
						conditions: question.conditions.map((condition: any, index: number) => {
							return {
								questionType: response.questions.find(
									(item: any) => item.id === condition.questionId
								).answerType,
								id: condition.questionId,
								type: condition.type,
								value: condition.value,
								index: index,
								questionNumber: condition.questionNumber,
							};
						}),
						image: question.image,
					};
				}),
			};

			dispatch(copySurvey(data));
			history.push('/services/survey/create');
		}
	};

	return (
		<div className="survey-list">
			{isLoading ? (
				<div className="survey-list-loader">
					<Loader variant="black" size="M" />
				</div>
			) : (
				<>
					{!!list?.length &&
						list.map((item, index) => (
							<div className="survey-list-item" key={index}>
								<img
									src={URL + item.cover.filePath}
									alt=""
									className="survey-list-item__img"
								/>
								<div className="survey-list-item__wrap">
									<Typography
										as="p"
										color="sc77"
										variant="spanRegular"
										className="survey-list-item__date"
									>
										{convertNormalDate(item.startDate)} -{' '}
										{convertNormalDate(item.endDate)}
									</Typography>

									<Typography
										as="p"
										weight="bold"
										variant="h4"
										className="survey-list-item__title"
									>
										{item.title}
									</Typography>

									<Link
										to={`/services/survey/take/${item.id}`}
										className="survey-list-item__link"
									>
										<Button
											variant={TypeButton.QUATERNARY}
											className="survey-list-item__link-btn"
										>
											Посмотреть опрос
										</Button>
									</Link>

									{!isMobile && (
										<div className="survey-list-admin">
											<Button
												variant={TypeButton.LINKCAPS}
												onClick={() => copyHandle(item)}
											>
												<Icon
													iconName="Copy"
													className="survey-list-admin__icon"
												/>
												Копировать
											</Button>

											<Link
												to={`/services/survey/analitics/${item.id}`}
												className="survey-list-admin__link left"
											>
												<Button variant={TypeButton.LINKCAPS}>
													<Icon
														iconName="PieChart"
														className="survey-list-admin__icon"
													/>
													аналитика
												</Button>
											</Link>
										</div>
									)}
								</div>
							</div>
						))}
				</>
			)}
		</div>
	);
};
