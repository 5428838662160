import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, TypeButton, Loader, Typography } from '@maxi-innovation/ui-kit-frontend';

import { URL } from 'constant';
import { IQuestionsTypes, ISurvey } from './types';
import { NotificationHelper } from 'action/notificationHelper';
import { exportExcelRest, getAnaliticsSurveyRest } from 'shared/api/survey';
import { FormUserBlock } from 'core/components';
import { convertNormalDate } from 'core/utils';
import { TextQuestion, OtherQuestion } from './questions';

import './style.scss';

const Components: IQuestionsTypes = {
	TEXT: TextQuestion,
	SCALE: OtherQuestion,
	SINGLE: OtherQuestion,
	MULTIPLE: OtherQuestion,
};

export const SurveyAnalitics = () => {
	const { id } = useParams<any>();
	const dispatch = useDispatch();
	const [survey, setSurvey] = useState<ISurvey>();
	const [isLoadingPage, setIsLoadingPage] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		(async () => {
			if (!id) return;
			setIsLoadingPage(true);
			const { response, responseCode, errorMessage } = await getAnaliticsSurveyRest(id);

			if (responseCode) {
				dispatch(NotificationHelper(errorMessage, responseCode));
			} else {
				setSurvey(response);
			}
			setIsLoadingPage(false);
		})();
	}, [id, dispatch]);

	const getExcelHandle = async () => {
		setIsLoading(true);
		const { response, responseCode } = await exportExcelRest(+id);

		if (responseCode === 0) {
			if (response.base64) {
				const link = document.createElement('a');
				link.setAttribute(
					'href',
					`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response.base64}`
				);
				link.setAttribute('download', response.fileName);
				link.style.visibility = 'hidden';
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		}
		setIsLoading(false);
	};

	return survey && !isLoadingPage ? (
		<div className="survey-analitics">
			<div className="survey-analitics-header">
				<img
					src={URL + survey.cover.filePath}
					alt=""
					className="survey-analitics-header__img"
				/>

				<div className="survey-analitics-header__wrap">
					<Typography
						className="survey-analitics-header__title"
						variant="h2"
						weight="bold"
						as="p"
					>
						{survey.title}
					</Typography>

					<div className="survey-author">
						<p className="survey-author__label">Автор опроса</p>
						<FormUserBlock
							id={survey.author.id}
							avatarFile={survey.author.avatarFile}
							surname={survey.author.surname}
							name={survey.author.name}
							middlename={survey.author.middlename ?? ''}
							position={survey.author.position}
						/>
					</div>

					<div className="survey-analitics-data">
						<div className="survey-analitics-data__wrap">
							<Typography className="survey-analitics-data__text">
								Собрано ответов <b>{survey.answerCount}</b>
							</Typography>
							{!!survey.participantCount && (
								<Typography className="survey-analitics-data__text">
									Всего участников <b>{survey.participantCount}</b>
								</Typography>
							)}
							<Typography className="survey-analitics-data__text">
								Начало опроса <b>{convertNormalDate(survey.startDate)}</b>
							</Typography>
							<Typography className="survey-analitics-data__text">
								Завершение опроса <b>{convertNormalDate(survey.endDate)}</b>
							</Typography>
						</div>

						{survey.anonymous && (
							<p className="survey-analitics-data__text">Анонимный опрос</p>
						)}
					</div>
				</div>
			</div>

			{survey.questionAnswers.map((item, index) => {
				const Component = Components[item.answerType];
				return item.answers.length ? (
					<Component question={item} questionNumber={index + 1} key={item.id} />
				) : null;
			})}

			<div className="survey-analitics__wrap">
				<Button
					variant={TypeButton.PRIMARY}
					type="button"
					onClick={getExcelHandle}
					disabled={isLoading}
					className="survey-analitics__excel"
				>
					Выгрузить ответы в Excel
				</Button>
			</div>
		</div>
	) : (
		<div className="survey-list-loader">
			<Loader variant="black" size="M" />
		</div>
	);
};
