import React, { FC } from 'react';
import { SelectComponent } from '@maxi-innovation/ui-kit-frontend';

import { IQuestions } from '../types';

const types = [
	{
		value: 'SINGLE',
		label: 'Один ответ',
	},
	{
		value: 'MULTIPLE',
		label: 'Множественный выбор',
	},
	{
		value: 'TEXT',
		label: 'Текст',
	},
	{
		value: 'SCALE',
		label: 'Шкала',
	},
];

interface IProps {
	questionSchema: IQuestions;
	changeType: (type: string, questionNumber: number) => void;
}

export const QuestionTypes: FC<IProps> = ({ changeType, questionSchema }) => {
	const changeTypeHandle = (value: string | number) => {
		changeType(value as string, questionSchema.questionNumber);
	};

	return (
		<div className="type-survey">
			<p className="type-survey__label">Тип вопроса</p>
			<SelectComponent
				placeholder="Выберите тип вопроса"
				types={types}
				onChange={changeTypeHandle}
				defaultValue={types.find(item => item.value === questionSchema.type)}
			/>
		</div>
	);
};
