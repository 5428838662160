import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, TypeButton, Typography } from '@maxi-innovation/ui-kit-frontend';

import { NotificationHelper } from 'action/notificationHelper';
import { completeQuestionRest } from 'shared/api/survey';

interface IProps {
	surveyId: number;
	goToPrevQuestion: (questionId: undefined) => void;
	setData: any;
}

export const LastStep: FC<IProps> = ({ surveyId, goToPrevQuestion, setData }) => {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);

	const lastStepHandler = async () => {
		setIsLoading(true);

		const { response, responseCode, errorMessage } = await completeQuestionRest(surveyId);

		if (responseCode) {
			dispatch(NotificationHelper(errorMessage, responseCode));
		} else {
			let data = undefined;

			if (response.finishText && response.finishButtonName && response.finishButtonLink) {
				data = response;
			}

			setData({
				state: 'ALREADY_COMPLETED',
				response: data,
			});
		}
	};

	return (
		<div className="take-question">
			<div className="take-question__wrap">
				<div className="question-complete">
					<Typography as="p" className="question-complete__text">
						Вы ответили на все вопросы. <br />
						Нажмите «Отправить», чтобы сохранить результаты.
					</Typography>
				</div>
			</div>

			<div className="take-question__wrap">
				<div className="question-controll">
					<Button
						variant={TypeButton.QUATERNARY}
						onClick={() => goToPrevQuestion(undefined)}
					>
						назад
					</Button>

					<Button
						variant={TypeButton.PRIMARY}
						className="question-controll__button"
						onClick={lastStepHandler}
						disabled={isLoading}
					>
						отправить
					</Button>
				</div>
			</div>
		</div>
	);
};
