import React, { FC } from 'react';
import { Typography } from '@maxi-innovation/ui-kit-frontend';

import { IQuestionComponentProps } from '../types';

export const TextQuestion: FC<IQuestionComponentProps> = ({ question, questionNumber }) => {
	return (
		<div className="question-analitic">
			<Typography as="p" variant="h4" className="question-analitic__title">
				{questionNumber}. {question.text}
			</Typography>

			<div className="analitic-question-answers">
				{question.answers.map((item, index) => (
					<div className="analitic-question-answers__item" key={index}>
						{item.author && (
							<Typography
								as="p"
								weight="bold"
								className="analitic-question-answers__name"
							>{`${item.author.surname} ${item.author.name.slice(
								0,
								1
							)}.`}</Typography>
						)}
						<Typography as="p" className="analitic-question-answers__text">
							{item.text}
						</Typography>
					</div>
				))}
			</div>
		</div>
	);
};
