import React, { FC } from 'react';
import { Typography } from '@maxi-innovation/ui-kit-frontend';

interface IProps {
	specialId?: number;
	finishText?: string;
	finishButtonName?: string;
	finishButtonLink?: string;
}

export const AlreadyStep: FC<IProps> = ({
	finishText,
	finishButtonName,
	finishButtonLink,
	specialId,
}) => {
	return (
		<div className="take-question">
			<div className="take-question__wrap">
				{finishText && finishButtonLink && finishButtonName ? (
					<div className="special-question-compleate">
						<p className="special-question-compleate__text">{finishText}</p>

						<a
							href={`${finishButtonLink}${specialId ? `?id=${specialId}` : ''}`}
							className="special-question-compleate__link"
						>
							{finishButtonName}
						</a>
					</div>
				) : (
					<div className="question-complete">
						<svg role="img" className="question-complete__icon">
							<use
								xlinkHref={process.env.PUBLIC_URL + '/img/sprite.svg#guide-main'}
							></use>
						</svg>
						<Typography as="p" className="question-complete__text">
							Благодарим за прохождение опроса!
						</Typography>
					</div>
				)}
			</div>
		</div>
	);
};
